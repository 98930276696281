.metismenu {
  margin: 0;
}

.metismenu li {
  display: block;
  width: 100%;
}

.metismenu .mm-collapse {
  display: none;
}

.metismenu .mm-collapse:not(.mm-show) {
  display: none;
}

.metismenu .mm-collapse.mm-show {
  display: block;
}

.metismenu .mm-collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition-timing-function: ease;
          transition-timing-function: ease;
  -webkit-transition-duration: .35s;
          transition-duration: .35s;
  -webkit-transition-property: height, visibility;
  transition-property: height, visibility;
}

.vertical-menu {
  width: 250px;
  z-index: 1005;
  background: #ffffff;
  bottom: 0;
  margin-top: 0;
  position: fixed;
  top: 0;
  -webkit-box-shadow: 0 2px 4px rgba(15, 34, 58, 0.12);
          box-shadow: 0 2px 4px rgba(15, 34, 58, 0.12);
}

.vertical-menu .vertical-menu-btn {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}

.main-content {
  margin-left: 250px;
}

.main-content .content {
  padding: 0 15px 10px 15px;
  margin-top: 70px;
}

.sidebar-menu-scroll {
  height: calc(100% - 50px);
  margin-top: 50px;
}

#sidebar-menu {
  padding: 10px 0 30px 0;
}

#sidebar-menu .mm-active > .has-arrow:after {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}

#sidebar-menu .has-arrow:after {
  content: "\F0140";
  font-family: 'Material Design Icons';
  display: block;
  float: right;
  -webkit-transition: -webkit-transform .2s;
  transition: -webkit-transform .2s;
  transition: transform .2s;
  transition: transform .2s, -webkit-transform .2s;
  font-size: 1rem;
}

#sidebar-menu ul li a {
  display: block;
  padding: .6rem 1.1rem;
  color: #7b8190;
  position: relative;
  font-size: 15px;
  font-weight: 500;
  -webkit-transition: all .4s;
  transition: all .4s;
  margin: 0 10px;
  border-radius: 3px;
}

#sidebar-menu ul li a i {
  display: inline-block;
  min-width: 1.5rem;
  padding-bottom: .125em;
  font-size: 1.05rem;
  line-height: 1.40625rem;
  vertical-align: middle;
  color: #7b8190;
  -webkit-transition: all .4s;
  transition: all .4s;
}

#sidebar-menu ul li a:hover {
  color: #383c40;
}

#sidebar-menu ul li a:hover i {
  color: #383c40;
}

#sidebar-menu ul li .badge {
  margin-top: 4px;
}

#sidebar-menu ul li ul.sub-menu {
  padding: 0;
}

#sidebar-menu ul li ul.sub-menu li a {
  padding: .4rem 1.5rem .4rem 3.5rem;
  font-weight: 400;
  color: #7b8190;
  margin: 0;
  background-color: transparent;
  font-size: 14.4px;
}

#sidebar-menu ul li ul.sub-menu li a:hover {
  color: #383c40;
}

#sidebar-menu ul li ul.sub-menu li ul.sub-menu {
  padding: 0;
}

#sidebar-menu ul li ul.sub-menu li ul.sub-menu li a {
  padding: .4rem 1.5rem .4rem 4.5rem;
  font-size: 13.5px;
}

.menu-title {
  padding: 12px 20px !important;
  letter-spacing: .05em;
  pointer-events: none;
  cursor: default;
  font-size: 11px;
  text-transform: uppercase;
  color: #7b8190;
  font-weight: 600;
}

.mm-active {
  color: #00AF50 !important;
}

.mm-active > a {
  background-color: #f3f8fb;
  color: #00AF50 !important;
}

.mm-active > a > i {
  color: #00AF50 !important;
}

.mm-active .active {
  color: #00AF50 !important;
}

.mm-active .active i {
  color: #00AF50 !important;
}

.mm-active > i {
  color: #00AF50 !important;
}

@media (max-width: 992px) {
  .vertical-menu {
    display: none;
    top: 50px;
  }
  .vertical-menu .sidebar-menu-scroll {
    height: 100%;
    margin-top: 0;
  }
  .vertical-menu .navbar-brand-box,
  .vertical-menu .vertical-menu-btn {
    display: none;
  }
  .main-content {
    margin-left: 0 !important;
  }
  body.sidebar-enable .vertical-menu {
    display: block;
  }
}

@media (min-width: 769px) {
  .vertical-collpsed {
    min-height: 1450px;
  }
}

.vertical-collpsed .main-content {
  margin-left: 70px;
}

.vertical-collpsed .navbar-brand-box {
  width: 70px !important;
}

.vertical-collpsed .logo span.logo-lg {
  display: none;
}

.vertical-collpsed .logo span.logo-sm {
  display: block;
}

.vertical-collpsed .vertical-menu {
  position: absolute;
  width: 70px !important;
  z-index: 1001;
}

.vertical-collpsed .vertical-menu .simplebar-mask,
.vertical-collpsed .vertical-menu .simplebar-content-wrapper {
  overflow: visible !important;
}

.vertical-collpsed .vertical-menu .simplebar-scrollbar,
.vertical-collpsed .vertical-menu .vertical-menu-btn {
  display: none !important;
}

.vertical-collpsed .vertical-menu .simplebar-offset {
  bottom: 0 !important;
}

.vertical-collpsed .vertical-menu #sidebar-menu .menu-title,
.vertical-collpsed .vertical-menu #sidebar-menu .badge,
.vertical-collpsed .vertical-menu #sidebar-menu .collapse.in {
  display: none !important;
}

.vertical-collpsed .vertical-menu #sidebar-menu .nav.collapse {
  height: inherit !important;
}

.vertical-collpsed .vertical-menu #sidebar-menu .has-arrow:after {
  display: none;
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul > li {
  position: relative;
  white-space: nowrap;
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul > li > a {
  padding: 15px 0;
  -webkit-transition: none;
  transition: none;
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul > li > a:hover, .vertical-collpsed .vertical-menu #sidebar-menu > ul > li > a:active, .vertical-collpsed .vertical-menu #sidebar-menu > ul > li > a:focus {
  color: #383c40;
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul > li > a i {
  font-size: 1.3rem;
  text-align: center;
  min-width: 50px;
  padding-bottom: 0;
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul > li > a span {
  display: none;
  padding-left: 25px;
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > a {
  position: relative;
  width: calc(180px + 70px);
  color: #00AF50;
  background-color: #f3f8fb;
  -webkit-transition: none;
  transition: none;
  -webkit-box-shadow: inset 0 3px 10px 0 rgba(154, 161, 171, 0.2);
          box-shadow: inset 0 3px 10px 0 rgba(154, 161, 171, 0.2);
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > a i {
  color: #00AF50;
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > a span {
  display: inline;
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > ul {
  display: block;
  left: 70px;
  position: absolute;
  width: 190px;
  height: auto !important;
  -webkit-box-shadow: 3px 5px 10px 0 rgba(54, 61, 71, 0.1);
          box-shadow: 3px 5px 10px 0 rgba(54, 61, 71, 0.1);
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > ul ul {
  -webkit-box-shadow: 3px 5px 10px 0 rgba(54, 61, 71, 0.1);
          box-shadow: 3px 5px 10px 0 rgba(54, 61, 71, 0.1);
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > ul a {
  -webkit-box-shadow: none;
          box-shadow: none;
  padding: 8px 20px;
  position: relative;
  width: 190px;
  z-index: 6;
  color: #7b8190;
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > ul a:hover {
  color: #383c40;
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul ul {
  padding: 5px 0;
  z-index: 9999;
  display: none;
  background-color: #ffffff;
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul ul li:hover > ul {
  display: block;
  left: 190px;
  height: auto !important;
  margin-top: -36px;
  position: absolute;
  width: 190px;
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul ul li > a span.pull-right {
  position: absolute;
  right: 20px;
  top: 12px;
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul ul li.active a {
  color: #f8f9fa;
}

body[data-sidebar="dark"] .vertical-menu {
  background: #1c2742;
}

body[data-sidebar="dark"] .vertical-menu .vertical-menu-btn {
  color: #8c98bb;
}

body[data-sidebar="dark"] #sidebar-menu ul li a {
  color: #8c98bb;
}

body[data-sidebar="dark"] #sidebar-menu ul li a i {
  color: #6d7794;
}

body[data-sidebar="dark"] #sidebar-menu ul li a:hover {
  color: #ffffff;
}

body[data-sidebar="dark"] #sidebar-menu ul li a:hover i {
  color: #ffffff;
}

body[data-sidebar="dark"] #sidebar-menu ul li ul.sub-menu li a {
  color: #7b85a2;
}

body[data-sidebar="dark"] #sidebar-menu ul li ul.sub-menu li a:hover {
  color: #ffffff;
}

body[data-sidebar="dark"].vertical-collpsed {
  min-height: 1300px;
}

body[data-sidebar="dark"].vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > a {
  background: #1f2b49;
  color: #ffffff;
}

body[data-sidebar="dark"].vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > a i {
  color: #ffffff;
}

body[data-sidebar="dark"].vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > ul a {
  color: #7b85a2;
}

body[data-sidebar="dark"].vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > ul a:hover {
  color: #ffffff;
}

body[data-sidebar="dark"].vertical-collpsed .vertical-menu #sidebar-menu > ul ul {
  background-color: #1c2742;
}

body[data-sidebar="dark"].vertical-collpsed .vertical-menu #sidebar-menu ul li.mm-active .active {
  color: #00AF50 !important;
}

body[data-sidebar="dark"].vertical-collpsed .vertical-menu #sidebar-menu ul li.mm-active .active i {
  color: #00AF50 !important;
}

body[data-sidebar="dark"] .mm-active {
  color: #ffffff !important;
}

body[data-sidebar="dark"] .mm-active > i {
  color: #ffffff !important;
}

body[data-sidebar="dark"] .mm-active > a {
  background-color: #293552;
  color: #ffffff !important;
}

body[data-sidebar="dark"] .mm-active > a > i {
  color: #ffffff !important;
}

body[data-sidebar="dark"] .mm-active .active {
  color: #ffffff !important;
}

body[data-sidebar="dark"] .mm-active .active i {
  color: #ffffff !important;
}

body[data-sidebar="dark"] .menu-title {
  color: #6d7794;
}

body[data-layout="horizontal"] .main-content {
  margin-left: 0 !important;
}

body[data-sidebar-size="small"] .navbar-brand-box {
  width: 160px;
}

@media (max-width: 991.98px) {
  body[data-sidebar-size="small"] .navbar-brand-box {
    width: auto;
  }
}

body[data-sidebar-size="small"] .vertical-menu {
  width: 160px;
  text-align: center;
}

body[data-sidebar-size="small"] .vertical-menu .has-arrow:after,
body[data-sidebar-size="small"] .vertical-menu .badge {
  display: none !important;
}

body[data-sidebar-size="small"] .vertical-menu .vertical-menu-btn {
  display: none;
}

body[data-sidebar-size="small"] .main-content {
  margin-left: 160px;
}

body[data-sidebar-size="small"] #page-topbar, body[data-sidebar-size="small"] .footer {
  left: 160px;
}

@media (max-width: 991.98px) {
  body[data-sidebar-size="small"] #page-topbar, body[data-sidebar-size="small"] .footer {
    left: 0;
  }
}

body[data-sidebar-size="small"] #sidebar-menu ul li.menu-title {
  background-color: #fafafa;
}

body[data-sidebar-size="small"] #sidebar-menu ul li a i {
  display: block;
}

body[data-sidebar-size="small"] #sidebar-menu ul li ul.sub-menu li a {
  padding-left: 1.5rem;
}

body[data-sidebar-size="small"] #sidebar-menu ul li ul.sub-menu li ul.sub-menu li a {
  padding-left: 1.5rem;
}

body[data-sidebar-size="small"][data-sidebar="dark"] #sidebar-menu ul li.menu-title {
  background-color: #1f2b49;
}

body[data-sidebar-size="small"]:not(.vertical-collpsed) .navbar-header .vertical-menu-btn {
  display: block;
}

body[data-sidebar-size="small"].vertical-collpsed .main-content {
  margin-left: 70px;
}

body[data-sidebar-size="small"].vertical-collpsed .vertical-menu #sidebar-menu {
  text-align: left;
}

body[data-sidebar-size="small"].vertical-collpsed .vertical-menu #sidebar-menu > ul > li > a i {
  display: inline-block;
}

body[data-sidebar-size="small"].vertical-collpsed #page-topbar, body[data-sidebar-size="small"].vertical-collpsed .footer {
  left: 70px;
}

body[data-sidebar="colored"] .vertical-menu {
  background-color: #00AF50;
}

body[data-sidebar="colored"] .vertical-menu .vertical-menu-btn {
  color: rgba(255, 255, 255, 0.8);
}

body[data-sidebar="colored"] .navbar-brand-box {
  background-color: #00AF50;
}

body[data-sidebar="colored"] .navbar-brand-box .logo-dark {
  display: none;
}

body[data-sidebar="colored"] .navbar-brand-box .logo-light {
  display: block;
}

body[data-sidebar="colored"] .mm-active {
  color: #fff !important;
}

body[data-sidebar="colored"] .mm-active > a {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff !important;
}

body[data-sidebar="colored"] .mm-active > a > i {
  color: #fff !important;
}

body[data-sidebar="colored"] .mm-active > i, body[data-sidebar="colored"] .mm-active .active {
  color: #fff !important;
}

body[data-sidebar="colored"] #sidebar-menu ul li.menu-title {
  color: rgba(255, 255, 255, 0.7);
}

body[data-sidebar="colored"] #sidebar-menu ul li a {
  color: rgba(255, 255, 255, 0.7);
}

body[data-sidebar="colored"] #sidebar-menu ul li a i {
  color: rgba(255, 255, 255, 0.7);
}

body[data-sidebar="colored"] #sidebar-menu ul li a.waves-effect .waves-ripple {
  background: rgba(255, 255, 255, 0.1);
}

body[data-sidebar="colored"] #sidebar-menu ul li ul.sub-menu li a {
  color: rgba(255, 255, 255, 0.6);
}

body[data-sidebar="colored"] #sidebar-menu ul li .badge.badge-primary {
  background-color: #fff;
  color: #00AF50;
}

body[data-sidebar="colored"].vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > a {
  background-color: #647be9;
  color: #fff;
}

body[data-sidebar="colored"].vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > a i {
  color: #fff;
}

body[data-sidebar="colored"].vertical-collpsed .vertical-menu #sidebar-menu ul li.mm-active .active {
  color: #00AF50 !important;
}
/*# sourceMappingURL=main.css.map */